export class Install
{
    constructor()
    {
        this.deferredPrompt
    }

    run()
    {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register("ServiceWorker.js")
                .then(() => {})
        }

        window.addEventListener("beforeinstallprompt", (e) => {
            this.deferredPrompt = e

            this.install()
        })
    }

    install()
    {
        this.deferredPrompt.prompt()

        this.deferredPrompt.userChoice.then((choiceResult) => {
            this.deferredPrompt = null
        })
    }
}
