export class Header
{
	strap = document.querySelector('[data-s~="Header"]')

    show()
    {
        this.strap.style.paddingTop = null
    }

    hide()
    {
        this.strap.style.paddingTop = 0
    }

    check()
    {
        if (window.pageYOffset > 100) {
            this.hide()
        } else if (window.pageYOffset == 0) {
            this.show()
        }
    }

    watch()
    {
        document.addEventListener("load", () => {
            this.check()
        })
        document.addEventListener("scroll", () => {
            this.check()
        })
        document.addEventListener("resize", () => {
            this.check()
        })
    }
}
