import { Preloader } from "./../Service/Preloader.js"
import { Counter } from "./../Service/Counter.js"
import { Modal } from "./../Service/Modal.js"
import { Header } from "./../Service/Header.js"
import { Switch } from "./../Service/Switch.js"
import { Icons } from "../Service/Icons.js"
import { Animate } from "./../Service/Animate.js"
import { Install } from "./../Service/Install.js"
import { Cookies } from "./../Service/Cookies.js"
import { Languages } from "./../Service/Languages.js"

export class Application
{
    constructor(store)
    {
        this.store = store

        this.store.service.Preloader = new Preloader()
        this.store.service.Counter = new Counter()
        this.store.service.Modal = new Modal()
        this.store.service.Header = new Header()
        this.store.service.Animate = new Animate()
        this.store.service.Switch = new Switch()
        this.store.service.Icons = new Icons()
        this.store.service.Install = new Install()
        this.store.service.Cookies = new Cookies()
        this.store.service.Languages = new Languages()
    }

    run()
    {
        this.store.service.Preloader.watch()
        this.store.service.Counter.watch()
        this.store.service.Modal.watch()
        this.store.service.Header.watch()
        this.store.service.Animate.run()
        this.store.service.Switch.watch()
        this.store.service.Icons.run()
        this.store.service.Icons.watch()
        this.store.service.Install.run()
        this.store.service.Cookies.run()
        this.store.service.Cookies.watch()
        this.store.service.Languages.run()
    }
}
