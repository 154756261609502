import ScrollReveal from "scrollreveal"

export class Animate
{
	elements = document.querySelectorAll('[data-s~="Animate"]')

    pattern = {
        delay: null,
        distance: null,
        duration: null,
        easing: null,
        interval: null,
        opacity: null,
        origin: null,
        rotate: null,
        scale: null,
        cleanup: null,
        container: null,
        desktop: null,
        mobile: null,
        reset: null,
        useDelay: null,
        viewFactor: null,
        viewOffset: null
    }

    run()
    {
        this.elements.forEach(element => {
            this.check(element)
        })
    }

    animate(element, config)
    {
        ScrollReveal().reveal(element, config)
    }

    check(element)
    {
        let config = {}

        Object.keys(this.pattern).forEach(pattern => {
            if (element.hasAttribute("data-"+pattern)) {
                config[pattern] = element.getAttribute("data-"+pattern)
            }
        })

        this.animate(element, config)
    }
}
