export class Modal
{
    card = document.querySelector('[data-s~="Modal"]')
    buttonsShow = document.querySelectorAll('[data-s~="Modal:show"]')
    buttonHide = document.querySelector('[data-s~="Modal:hide"]')

    target = null
    status = false
    event = new CustomEvent("modal", { detail: {
        action: null,
        target: null,
    } })

    show()
    {
        if (!this.status) {
            this.status = true

            let o = document.querySelector('[data-s~="Modal:target"][data-uuid~="'+this.target+'"]')

            this.card.style.display = "flex"
            this.card.focus()
            o.style.display = "flex"
    
            this.event.detail.target = this.target
            this.event.detail.action = "show"
            dispatchEvent(this.event)    
    
            setTimeout(() => {
                o.style.transform = "translateX(0)"
            }, 250)
        }
    }

    hide()
    {
        this.status = false

        let o = document.querySelectorAll('[data-s~="Modal:target"]')

        this.card.style.display = null

        this.event.detail.target = this.target
        this.event.detail.action = "hide"
        dispatchEvent(this.event)

        o.forEach(e => {
            e.style.display = null
            e.style.transform = null
        })
    }

    watch()
    {
        this.buttonsShow.forEach(e => {
            e.addEventListener("click", () => {
                this.target = e.getAttribute("data-uuid")
                this.show()
            })
        })
        this.buttonHide && this.buttonHide.addEventListener("click", () => {
            this.hide()
        })
    }
}
